import { StaticImage } from "gatsby-plugin-image";
import React, { useCallback, useState } from "react";
import { sendJoinSlackEmail } from "../helpers/sendEmail";
import ThankYouModal from "./common/ThankYouModal";

const JoinCommunity = () => {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      // Send email through Mailchimp
      const result = await sendJoinSlackEmail(email);

      //check errors
      if (result.result === "error") {
        if (result.msg.includes("is already subscribed")) setShowModal(true);
        else
          setErrors({
            mainError: "Something went wrong. Please try again in about 5 minutes.",
          });
      } else if (result.result === "success") setShowModal(true);
    },
    [email]
  );

  return (
    <div className="border-black border-b-2 w-full" id="join-us">
      <div className="container flex flex-col justify-center h-full w-full items-center text-center gap-5 md:gap-16 xl:gap-16 py-5 md:py-10 xl:py-20 ">
        <h1 className="text-2xl md:text-4xl xl:text-5xl font-semibold">Join our Slack community </h1>

        <div className="text-base md:text-lg xl:text-xl font-mono w-full md:w-3/4 xl:w-3/4">
          <p className="mb-5">
            In this workspace, you can share your experience with Slashscore as well as ask for help if you encounter
            any issues while using it. It's also the place where you can chat away with fellow Slashscore fans from all
            over the world.
          </p>
          <p>
            We will also take notice of any useful feedback you share in<br></br>
            <span className="font-semibold">#slashscore-feedback</span> so that we can continue to improve this product.
          </p>
        </div>

        <div className="relative flex items-center justify-center text-center">
          <div className="relative hidden md:inline-block xl:inline-block">
            <StaticImage src="../images/join-slack-box.png" alt="Box" width={945} quality={100} />
            <StaticImage
              src="../images/box-dots.png"
              alt="Dots"
              width={190}
              className="-right-16 top-1/2 hidden"
              quality={100}
              style={{ position: "absolute" }}
            />
          </div>

          <div className="md:absolute xl:absolute flex flex-col gap-5 font-mono text-sm md:text-lg xl:text-xl w-full md:w-3/5 xl:w-2/3">
            <p className="font-semibold">
              You can join our community at any time - just fill out the information below and we'll send an invite to
              you right away!
            </p>
            <form onSubmit={handleSubmit} className="flex justify-center">
              <input
                type="email"
                placeholder="Your Email Address"
                className="border-black border-2 py-2.5 px-12 md:px-16 xl:px-16"
                onChange={(e) => setEmail(e.target.value)}
              />
              
              {errors ? <div className="text-red">{errors}</div> : null}

              <button type="submit" className="bg-black text-white p-3">
                Send Invite
              </button>
            </form>
          </div>
        </div>
      </div>

      {showModal ? <ThankYouModal open={showModal} close={true} handleClose={() => setShowModal(false)} /> : null}
    </div>
  );
};

export default JoinCommunity;
