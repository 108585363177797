import React from "react";
import { Helmet } from "react-helmet";

const SeoArticle = ({ path, title, metaDescription, featuredImage, keywords }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <meta charSet="utf-8" />
      <title>Slashscore | {title}</title>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta http-equiv="Content-Type" content="text/html; charset=ISO-8859-1" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {/* Bots */}
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      {/* App /> */}
      <meta name="description" content={metaDescription} />
      <meta name="theme-color" content="#008f68" />
      <meta name="keywords" content={keywords} />

      <meta name="author" content="Slashscore" />
      {/* Open Graph data */}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`Slashscore | ${title}`} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:url" content={path} />
      <meta property="og:site_name" content="Slashscore" />
      <meta property="og:image" content={featuredImage} />
      <meta property="og:image:width" content="2401" />
      <meta property="og:image:height" content="1261" />
      {/* Twitter Card data */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@slashcoreai" />
      <meta name="twitter:title" content={`Slashscore | ${title}`} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:creator" content="@slashcoreai" />
      {/* Facebook Card data */}
      <meta name="facebook:card" content="summary" />
      <meta name="facebook:site" content="@slashcoreai" />
      <meta name="facebook:title" content={`Slashscore | ${title}`} />
      <meta name="facebook:description" content={metaDescription} />
      <meta name="facebook:creator" content="@slashcoreai" />
      {/* Linkedin Card data */}
      <meta name="linkedin:card" content="summary" />
      <meta name="linkedin:site" content="@slashcoreai" />
      <meta name="linkedin:title" content={`Slashscore | ${title}`} />
      <meta name="linkedin:description" content={metaDescription} />
      <meta name="linkedin:creator" content="@slashcoreai" />
      {/* Geo */}
      <meta name="geo.position" content="latitude, longitude" />
      <meta name="geo.placename" content="Place Name" />
      <meta name="geo.region" content="Country Subdivision Code" />
      {/* Rights */}
      <meta name="dcterms.rightsHolder" content="WIN GRAVITY SRL" />
      <meta name="dcterms.rights" content="Statement of copyright" />
      <meta name="dcterms.dateCopyrighted" content="2022" />
      <meta name="copyright" content="WIN GRAVITY SRL" />
    </Helmet>
  );
};

export default SeoArticle;
