import React from "react";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";

import { graphql, Link } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import Header from "../common/Header";
import Footer from "../common/Footer";
import JoinCommunity from "../JoinCommunitySection";
import Newsletter from "../common/Newsletter";
import SeoArticle from "../common/SeoArticle";
import CookieBanner from "../CookieBanner";

const ArticleTemplate = ({ data }) => {
  const { markdownRemark, allMarkdownRemark } = data;

  const { frontmatter } = markdownRemark;

  const image = getImage(frontmatter.featuredImage);

  const renderedSummaries = frontmatter.summary.map((item, i) => {
    return (
      <div className="mt-2 text-sm" key={i}>
        - {item}
      </div>
    );
  });

  return (
    <div className="bg-gray">
      <SeoArticle
        title={frontmatter.title}
        metaDescription={frontmatter.metaDescription}
        path={`${process.env.HOST}${frontmatter.path}`}
        keywords={frontmatter.keywords}
        featuredImage={`${process.env.HOST}${frontmatter.featuredImage.childImageSharp.fluid.src}`}
      />
      <Header />
      <div className="container h-fit w-full gap-8 py-5 md:py-10 xl:py-10 flex flex-col">
        <div className="flex flex-col md:flex-col xl:flex-row items-center gap-5 md:gap-10 xl:gap-10">
          <GatsbyImage image={image} alt={frontmatter.title} className="rounded-md w-full" />

          <div className="flex flex-col gap-0 md:gap-10 xl:gap-10 w-full">
            <div>
              <Link to={`/updates/${frontmatter.category.toLowerCase()}/`}>
                <h6 className="mb-1 opacity-70">{frontmatter.category}</h6>
              </Link>
              <h1 className="mb-1 text-xl md:text-2xl xl:text-3xl">{frontmatter.title}</h1>
              <h2 className="font-mono text-base md:text-lg xl:text-lg">{frontmatter.shortDescription}</h2>
            </div>
            <div className="text-xs md:text-lg xl:text-lg order-first md:order-last xl:order-last text-right md:text-left xl:text-left">
              <p>Author: {frontmatter.author}</p>
              <p>Posted on: {new Date(frontmatter.date).toLocaleDateString("en-US", { dateStyle: "long" })}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-col xl:flex-row gap-10">
          <div className="bg-primary/90 shadow-md h-fit p-5 rounded-md w-full xl:w-2/5">
            <h3 className="text-lg">Summary</h3>
            {renderedSummaries}
          </div>
          <div className="w-full">
            <div className="border-black/20 border-b-[1px] flex justify-between mb-5">
              <div className="flex gap-2">
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20ZM11 7H12.5V12.25L17 14.92L16.25 16.15L11 13V7Z"
                    fill="#2E3161"
                  />
                </svg>
                <p>{frontmatter.readingTime}. read</p>
              </div>

              <div>
                <FacebookShareButton
                  className="no-outline"
                  url={`${process.env.HOST}/${frontmatter.path}`}
                  quote={frontmatter.shortDescription}
                >
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM13.2508 12.5271V19.0557H10.5495V12.5274H9.2V10.2776H10.5495V8.92678C10.5495 7.0914 11.3116 6 13.4766 6H15.279V8.25006H14.1524C13.3096 8.25006 13.2538 8.56447 13.2538 9.15125L13.2508 10.2773H15.2918L15.053 12.5271H13.2508Z"
                      fill="#000000"
                    />
                  </svg>
                </FacebookShareButton>

                <TwitterShareButton
                  className="no-outline mr-1 ml-1"
                  title={frontmatter.title}
                  url={`${process.env.HOST}/${frontmatter.path}`}
                >
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM11.6658 10.169L11.6406 9.75375C11.5651 8.67755 12.2282 7.69456 13.2774 7.31323C13.6635 7.17765 14.3182 7.1607 14.7463 7.27934C14.9142 7.33018 15.2331 7.49966 15.4598 7.65219L15.8711 7.93184L16.3243 7.78778C16.5761 7.71151 16.9119 7.5844 17.063 7.49966C17.2057 7.42339 17.3316 7.38102 17.3316 7.40645C17.3316 7.5505 17.021 8.042 16.7608 8.31317C16.4083 8.6945 16.509 8.72839 17.2224 8.47417C17.6505 8.33011 17.6589 8.33011 17.575 8.49112C17.5246 8.57586 17.2644 8.87245 16.9874 9.14362C16.5174 9.60969 16.4922 9.66054 16.4922 10.0503C16.4922 10.652 16.2068 11.9062 15.9214 12.5925C15.3926 13.8806 14.2595 15.211 13.1263 15.8805C11.5315 16.8211 9.40786 17.0584 7.61999 16.5075C7.02404 16.3211 6 15.8466 6 15.7618C6 15.7364 6.31057 15.7025 6.68829 15.694C7.4773 15.6771 8.26631 15.4568 8.93781 15.067L9.39108 14.7958L8.87066 14.6178C8.13201 14.3636 7.46891 13.7789 7.30103 13.2281C7.25067 13.0501 7.26746 13.0417 7.73751 13.0417L8.22434 13.0332L7.81305 12.8383C7.32621 12.5925 6.88134 12.1773 6.66311 11.7536C6.50362 11.4486 6.30218 10.6774 6.36093 10.6181C6.37772 10.5927 6.55399 10.6435 6.75544 10.7113C7.33461 10.9232 7.41015 10.8723 7.0744 10.5164C6.44487 9.87239 6.25181 8.91482 6.55399 8.0081L6.69668 7.60135L7.25067 8.15216C8.38383 9.26226 9.71843 9.92323 11.2461 10.1181L11.6658 10.169Z"
                      fill="#000000"
                    />
                  </svg>
                </TwitterShareButton>

                <LinkedinShareButton
                  title={frontmatter.title}
                  summary={frontmatter.shortDescription}
                  source="Slashscore"
                  url={`h${process.env.HOST}/${frontmatter.path}`}
                >
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM5.76086 9.93892H8.48033V18.1098H5.76086V9.93892ZM8.6593 7.41135C8.64165 6.6102 8.06874 6 7.13843 6C6.20812 6 5.59991 6.6102 5.59991 7.41135C5.59991 8.1959 6.19014 8.82367 7.10313 8.82367H7.12051C8.06874 8.82367 8.6593 8.1959 8.6593 7.41135ZM15.1565 9.74706C16.946 9.74706 18.2876 10.9151 18.2876 13.4249L18.2875 18.1098H15.5682V13.7384C15.5682 12.6404 15.1746 11.8911 14.1902 11.8911C13.4389 11.8911 12.9914 12.3962 12.7948 12.8841C12.7229 13.0589 12.7053 13.3025 12.7053 13.5467V18.11H9.98547C9.98547 18.11 10.0213 10.7059 9.98547 9.93914H12.7053V11.0965C13.0662 10.5401 13.7126 9.74706 15.1565 9.74706Z"
                      fill="#000000"
                    />
                  </svg>
                </LinkedinShareButton>
              </div>
            </div>
            <div
              className="border-black/20 border-b-[1px] article pr-3"
              dangerouslySetInnerHTML={{
                __html: markdownRemark.html,
              }}
            ></div>
          </div>
        </div>

        <div className="flex justify-center">
          <Newsletter variant="article" />
        </div>

        <h1 className="text-3xl">Continue reading</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-10 text-left leading-7">
          {allMarkdownRemark.edges.map((edge, index) => {
            const articleContent = edge.node.frontmatter;
            const image = getImage(articleContent.featuredImage);
            return (
              <div key={index} className="border-black/20 border-b-[1px]">
                <Link to={articleContent.path} className="hover:underline">
                  <GatsbyImage
                    image={image}
                    alt={articleContent.title}
                    className="rounded-md h-60 w-full object-cover"
                  />
                  <p className="opacity-60 mt-3">{articleContent.category}</p>
                  <h1 className="font-semibold mb-5 ">{articleContent.title}</h1>
                  <p className="font-mono mb-5">{articleContent.shortDescription}</p>
                </Link>
              </div>
            );
          })}
        </div>
      </div>

      <JoinCommunity />

      <Footer />
      <CookieBanner />
    </div>
  );
};

export const postQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        author
        category
        date
        readingTime
        shortDescription
        metaDescription
        keywords
        summary
        title
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 1000, height: 550, quality: 100)
            fluid {
              src
            }
          }
        }
      }
    }
    allMarkdownRemark(
      limit: 2
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "article" }, path: { ne: $path } } }
    ) {
      edges {
        node {
          frontmatter {
            featuredImage {
              childImageSharp {
                gatsbyImageData(width: 1000, height: 500, quality: 100)
                fluid {
                  src
                }
              }
            }
            path
            title
            category
            shortDescription
            keywords
            metaDescription
          }
        }
      }
    }
  }
`;

export default ArticleTemplate;
